import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Container,
  Avatar,
  AvatarGroup,
  IconButton,
  alpha,
  styled,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Chip,
  Tooltip,
  Paper,
  LinearProgress,
  Snackbar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Refresh as EmptyIcon,
  Receipt as ReceiptIcon,
  Group as GroupIcon,
  CalendarMonth as CalendarIcon,
  PersonAdd as PersonAddIcon,
  Edit as EditIcon,
  History as HistoryIcon,
  MoreVert as MoreVertIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  AccountBalance as AccountBalanceIcon,
  Close as CloseIcon,
  Send as SendIcon,
  Warning as WarningIcon,
  ExitToApp,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";
import {
  subscribeToGroup,
  getBillsByGroup,
  db,
  canLeaveGroup,
  leaveGroup,
} from "../backend-services/firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import GroupBillErrorBoundary from "./GroupBillErrorBoundary";
import GroupBillsList from "./GroupBillsList";
import InviteModal from "./invite/InviteModal";
import EditGroupModal from "./EditGroupModal";
import GroupActivityTimeline from "./GroupActivityTimeline";
import GroupSimplifiedDebts from "./GroupSimplifiedDebts";
import { where, onSnapshot } from "firebase/firestore";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      position: "fixed",
      bottom: 0,
      borderRadius: `${theme.shape.borderRadius * 2}px ${
        theme.shape.borderRadius * 2
      }px 0 0`,
      maxHeight: "90vh",
      background: `linear-gradient(145deg, ${alpha(
        "#1A1D24",
        0.98
      )} 0%, ${alpha("#141519", 0.98)} 100%)`,
      backdropFilter: "blur(10px)",
      border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    },
    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(145deg, ${alpha(
        "#1A1D24",
        0.98
      )} 0%, ${alpha("#141519", 0.98)} 100%)`,
      backdropFilter: "blur(10px)",
      borderRadius: theme.shape.borderRadius * 2,
      border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
      maxWidth: "500px",
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiBackdrop-root": {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      backdropFilter: "blur(8px)",
    },
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3),
  fontWeight: 600,
  "&.MuiButton-contained": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  "& .MuiTypography-root": {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  gap: theme.spacing(1),
}));

const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1200px",
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(8),
}));

const BackButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  minWidth: "unset",
  color: alpha(theme.palette.common.white, 0.7),
  "&:hover": {
    background: "none",
    color: alpha(theme.palette.common.white, 0.9),
  },
}));

const GroupHeader = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "4px",
  },
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const GroupTitleSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: theme.spacing(2),
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: "blur(8px)",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

const GroupTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const GroupStats = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const StatCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}));

const MemberSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 48,
  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(2),
  },
  "& .MuiTab-root": {
    minHeight: 48,
    padding: theme.spacing(1, 2),
    color: alpha(theme.palette.common.white, 0.7),
    fontSize: "0.875rem",
    fontWeight: 500,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.common.white,
    },
  },
  "& .MuiTabs-indicator": {
    height: 3,
    backgroundColor: theme.palette.primary.main,
  },
}));

const TabLabel = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`group-tabpanel-${index}`}
    aria-labelledby={`group-tab-${index}`}
  >
    {value === index && children}
  </Box>
);

const ContentContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderRadius: theme.shape.borderRadius * 2,
  textAlign: "center",
}));

const MobileTabSelector = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
    },
  },
}));

const MobileMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: "blur(10px)",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    borderRadius: theme.shape.borderRadius,
    minWidth: 200,
    marginTop: theme.spacing(1),
  },
}));

const MenuItemContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
}));

const GroupDetails = () => {
  const theme = useTheme();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [bills, setBills] = useState([]);
  const [loadingBills, setLoadingBills] = useState(true);
  const [billsError, setBillsError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [billsRefresh, setBillsRefresh] = useState(0);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [activities, setActivities] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [activitiesError, setActivitiesError] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const [groupStats, setGroupStats] = useState({
    totalBills: 0,
    pendingBills: 0,
    completedBills: 0,
    totalAmount: 0,
    settledAmount: 0,
    pendingAmount: 0,
    settlementProgress: 0,
  });
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false);
  const [leavingGroup, setLeavingGroup] = useState(false);
  const [cannotLeaveReason, setCannotLeaveReason] = useState(null);
  const [pendingBills, setPendingBills] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num)) {
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);
    }

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(num);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const getActiveTabLabel = () => {
    switch (activeTab) {
      case 0:
        return { icon: <ReceiptIcon />, label: `Bills (${totalBills})` };
      case 1:
        return {
          icon: <GroupIcon />,
          label: `Members (${group.members.length})`,
        };
      case 2:
        return {
          icon: <AccountBalanceIcon />,
          label: `Simplified Debt`,
        };
      case 3:
        return { icon: <HistoryIcon />, label: "Activity" };
      default:
        return { icon: <ReceiptIcon />, label: "Select Tab" };
    }
  };

  useEffect(() => {
    if (!groupId) return;

    console.log("Subscribing to group:", groupId);
    setLoading(true);
    setError("");

    const unsubscribe = subscribeToGroup(groupId, (updatedGroup) => {
      console.log("Received group data:", updatedGroup);
      if (updatedGroup) {
        setGroup(updatedGroup);
      } else {
        setError("Group not found");
      }
      setLoading(false);
    });

    return () => {
      console.log("Unsubscribing from group");
      unsubscribe();
    };
  }, [groupId]);
  useEffect(() => {
    if (!groupId || !group) return;

    const fetchBillsAndStats = async () => {
      setLoadingBills(true);
      setBillsError(null);

      try {
        const q = query(
          collection(db, "bills"),
          where("groupId", "==", groupId),
          where("status", "!=", "deleted"),
          orderBy("date", "desc")
        );

        const unsubscribe = onSnapshot(
          q,
          (snapshot) => {
            const updatedBills = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setBills(updatedBills);
            setGroupStats(calculateGroupStats(updatedBills));
          },
          (error) => {
            console.error("Error in bills subscription:", error);
            setBillsError("Failed to load group bills and statistics");
          }
        );

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching bills and stats:", error);
        setBillsError("Failed to load group bills and statistics");
      } finally {
        setLoadingBills(false);
      }
    };

    fetchBillsAndStats();
  }, [groupId, group]);
  useEffect(() => {
    if (!groupId || activeTab !== 2) return;

    const fetchActivities = async () => {
      try {
        setLoadingActivities(true);
        setActivitiesError(null);

        // Create reference to activities subcollection
        const activitiesRef = collection(db, "groups", groupId, "activities");
        const q = query(activitiesRef, orderBy("timestamp", "desc"));

        // Use getDocs instead of custom function
        const snapshot = await getDocs(q);
        const activitiesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setActivities(activitiesData);
      } catch (error) {
        console.error("Error fetching activities:", error);
        setActivitiesError("Failed to load group activities");
      } finally {
        setLoadingActivities(false);
      }
    };

    fetchActivities();
  }, [groupId, activeTab]);

  const handleBillUpdate = useCallback(async () => {
    try {
      setLoadingBills(true);
      setBillsError(null);

      const groupBills = await getBillsByGroup(groupId);
      const sortedBills = groupBills.sort((a, b) => {
        const dateA = a.date?.toDate?.() || new Date(a.date);
        const dateB = b.date?.toDate?.() || new Date(b.date);
        return dateB - dateA;
      });

      setBills(sortedBills);

      // Recalculate stats after bills update
      const stats = calculateGroupStats(sortedBills);
      setGroupStats(stats);
    } catch (error) {
      console.error("Error refreshing bills:", error);
      setBillsError("Failed to refresh bills data");
    } finally {
      setLoadingBills(false);
    }
  }, [groupId]);

  const handleBack = () => {
    navigate("/groups");
  };

  const handleAddBill = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleCloseMenu();
    navigate(`/groups/${groupId}/add-bill`);
  };

  const calculateGroupStats = (bills) => {
    let totalAmount = 0;
    let settledAmount = 0;
    let completedBills = 0;
    let pendingBills = 0;

    bills.forEach((bill) => {
      const billAmount = parseFloat(bill.amount || 0);
      totalAmount += billAmount;

      const allParticipantsSettled = bill.participants.every(
        (p) => p.settled || p.userId === bill.paidBy
      );

      if (bill.status === "completed" || allParticipantsSettled) {
        completedBills++;
        settledAmount += billAmount;
      } else {
        pendingBills++;
        const settledParticipants = bill.participants.filter(
          (p) => p.settled || p.userId === bill.paidBy
        );
        const settledShare = settledParticipants.reduce(
          (sum, p) => sum + parseFloat(p.share?.original || 0),
          0
        );
        settledAmount += settledShare;
      }
    });

    const pendingAmount = Math.max(0, totalAmount - settledAmount);
    const settlementProgress =
      totalAmount > 0 ? (settledAmount / totalAmount) * 100 : 0;

    return {
      totalBills: bills.length,
      pendingBills,
      completedBills,
      totalAmount,
      settledAmount,
      pendingAmount,
      settlementProgress,
    };
  };

  const handleGroupUpdate = useCallback(() => {
    setEditSuccess(true);
    setBillsRefresh((prev) => prev + 1);
    setTimeout(() => {
      setEditSuccess(false);
    }, 3000);
  }, []);

  const handleInviteSuccess = useCallback(() => {
    setInviteSuccess(true);
    setTimeout(() => setInviteSuccess(false), 3000);
  }, []);

  const handleCheckCanLeaveGroup = async () => {
    try {
      setLeavingGroup(true);
      const { canLeave, pendingBills } = await canLeaveGroup(groupId, user.uid);
      if (!canLeave) {
        setCannotLeaveReason(
          "You have pending bills that need to be settled before leaving the group"
        );
        setPendingBills(pendingBills);
      }
      setIsLeaveDialogOpen(true);
    } catch (error) {
      setError("Failed to check if you can leave the group");
      console.error("Error checking leave group status:", error);
    } finally {
      setLeavingGroup(false);
    }
  };

  const handleLeaveGroup = async () => {
    try {
      setLeavingGroup(true);
      await leaveGroup(groupId, user.uid);
      setSuccessMessage("You have left the group successfully");
      setTimeout(() => {
        navigate("/groups");
      }, 1500);
    } catch (error) {
      console.error("Error leaving group:", error);
      if (error.message.includes("pending bills")) {
        setCannotLeaveReason("You have pending bills that need to be settled before leaving the group");
      } else if (error.message.includes("creator cannot leave")) {
        setCannotLeaveReason("As the group creator, you cannot leave while other members exist. Transfer ownership or remove other members first.");
      } else {
        setError(error.message || "Failed to leave group");
      }
    } finally {
      setLeavingGroup(false);
      setIsLeaveDialogOpen(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "400px",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!group) {
    return (
      <Container>
        <Alert severity="error">Group not found</Alert>
      </Container>
    );
  }

  const isCreator = group.createdBy === user?.uid;
  const createdDate = group.createdAt?.toDate() || new Date();
  const totalBills = bills.length;

  const renderEmptyState = (type) => (
    <EmptyStateContainer>
      <EmptyIcon>
        {type === "bills" ? <ReceiptIcon /> : <GroupIcon />}
      </EmptyIcon>
      <Typography variant="h6" gutterBottom>
        {type === "bills" ? "No Bills Yet" : "No Members Yet"}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
        {type === "bills"
          ? `This group doesn't have any bills yet. Bills added will appear here.`
          : `This group doesn't have any members yet.`}
      </Typography>
    </EmptyStateContainer>
  );

  const renderBillsTab = () => {
    if (loadingBills) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (billsError) {
      return (
        <Alert
          severity="error"
          sx={{ my: 2 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setBillsRefresh((prev) => prev + 1)}
            >
              Retry
            </Button>
          }
        >
          {billsError}
        </Alert>
      );
    }

    if (!bills.length) {
      return (
        <Box
          sx={{
            textAlign: "center",
            py: 6,
            px: 2,
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.4),
            borderRadius: 2,
          }}
        >
          <ReceiptIcon
            sx={{ fontSize: 48, color: "primary.main", opacity: 0.5, mb: 2 }}
          />
          <Typography variant="h6" gutterBottom>
            No Bills Yet
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            Start by adding a bill to split expenses with group members
          </Typography>
        </Box>
      );
    }

    return (
      <GroupBillErrorBoundary
        onRetry={() => setBillsRefresh((prev) => prev + 1)}
      >
        <GroupBillsList
          bills={bills}
          groupId={groupId}
          loading={loadingBills}
          onBillUpdate={handleBillUpdate}
        />
      </GroupBillErrorBoundary>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "400px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Container>
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      </Container>
    );
  }
  if (!group) {
    return (
      <Container>
        <Alert severity="error">Group not found</Alert>
      </Container>
    );
  }
  return (
    <PageContainer>
      <Box sx={{ mb: 2 }}>
        <BackButton startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back to Groups
        </BackButton>
      </Box>
      <GroupHeader>
        <HeaderContent>
          <GroupTitleSection>
            <GroupTitle>
              <Typography variant="h4" color="primary" fontWeight="bold">
                {group.name}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CalendarIcon sx={{ fontSize: 16, color: "text.secondary" }} />
                <Typography variant="body2" color="text.secondary">
                  Created {createdDate.toLocaleDateString()}
                </Typography>
                {isCreator && (
                  <Chip
                    label="Admin"
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: 24 }}
                  />
                )}
              </Box>
            </GroupTitle>
            <Box>
              <IconButton
                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                sx={{
                  color: "text.secondary",
                  "&:hover": { color: "text.primary" },
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <StyledMenu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleAddBill}>
                  <IconButton>
                    <AddIcon fontSize="small" />
                  </IconButton>
                  Add Bill
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setIsInviteModalOpen(true);
                  }}
                >
                  <IconButton>
                    <PersonAddIcon fontSize="small" />
                  </IconButton>
                  Invite Members
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setIsEditModalOpen(true);
                  }}
                >
                  <IconButton>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  Edit Group
                </MenuItem>
                {!isCreator && (
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleCheckCanLeaveGroup();
                    }}
                    sx={{
                      color: theme.palette.warning.main,
                      "&:hover": {
                        backgroundColor: alpha(theme.palette.warning.main, 0.1),
                      },
                    }}
                  >
                    <IconButton>
                      <ExitToApp fontSize="small" />
                    </IconButton>
                    Leave Group
                  </MenuItem>
                )}
              </StyledMenu>
            </Box>
          </GroupTitleSection>

          <MemberSection>
            <AvatarGroup
              max={5}
              sx={{
                "& .MuiAvatar-root": {
                  width: 40,
                  height: 40,
                  fontSize: "1rem",
                  border: "2px solid",
                  borderColor: "background.paper",
                },
              }}
            >
              {group.members.map((member) => (
                <Tooltip
                  key={member.userId}
                  title={member.displayName || member.email}
                >
                  <Avatar
                    src={member.photoURL || "/api/placeholder/40/40"}
                    alt={member.displayName}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
            <Box>
              <Typography variant="subtitle1" fontWeight={500}>
                {group.members.length}{" "}
                {group.members.length === 1 ? "Member" : "Members"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {group.members.some((m) => m.userId === user?.uid)
                  ? "You're a member"
                  : "You're not a member"}
              </Typography>
            </Box>
          </MemberSection>

          <GroupStats>
            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Total Bills
              </Typography>
              <Typography variant="h4" color="primary" fontWeight="bold">
                {groupStats.totalBills}
              </Typography>
            </StatCard>

            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Pending Bills
              </Typography>
              <Typography variant="h4" color="warning.main" fontWeight="bold">
                {groupStats.pendingBills}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatCurrency(groupStats.pendingAmount)} remaining
              </Typography>
            </StatCard>

            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Completed Bills
              </Typography>
              <Typography variant="h4" color="success.main" fontWeight="bold">
                {groupStats.completedBills}
              </Typography>
              <Typography variant="caption" color="success.main">
                {formatCurrency(groupStats.settledAmount)} settled
              </Typography>
            </StatCard>

            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Settlement Progress
              </Typography>
              <Box sx={{ position: "relative", pt: 0.5 }}>
                <LinearProgress
                  variant="determinate"
                  value={groupStats.settlementProgress}
                  sx={{
                    height: 8,
                    borderRadius: 1,
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 1,
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    position: "absolute",
                    [theme.breakpoints.down("sm")]: {
                      position: "static",
                      display: "block",
                      textAlign: "left",
                      mt: 0.5,
                    },
                    [theme.breakpoints.up("sm")]: {
                      right: 0,
                      top: "100%",
                      mt: 0.5,
                    },
                  }}
                >
                  {groupStats.settlementProgress.toFixed(1)}%
                </Typography>
              </Box>
            </StatCard>
          </GroupStats>
        </HeaderContent>
      </GroupHeader>
      <Box sx={{ display: { xs: "block", sm: "none" }, mb: 2 }}>
        <MobileTabSelector onClick={handleMobileMenuOpen}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {getActiveTabLabel().icon}
            <Typography variant="subtitle1">
              {getActiveTabLabel().label}
            </Typography>
          </Box>
          <Box sx={{ ml: "auto" }}>
            <IconButton size="small">
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
        </MobileTabSelector>

        <MobileMenu
          anchorEl={mobileMenuAnchor}
          open={Boolean(mobileMenuAnchor)}
          onClose={handleMobileMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              setActiveTab(0);
              handleMobileMenuClose();
            }}
            selected={activeTab === 0}
          >
            <MenuItemContent>
              <ReceiptIcon />
              <Typography>Bills ({totalBills})</Typography>
            </MenuItemContent>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setActiveTab(1);
              handleMobileMenuClose();
            }}
            selected={activeTab === 1}
          >
            <MenuItemContent>
              <GroupIcon />
              <Typography>Members ({group.members.length})</Typography>
            </MenuItemContent>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setActiveTab(2);
              handleMobileMenuClose();
            }}
            selected={activeTab === 2}
          >
            <MenuItemContent>
              <AccountBalanceIcon />
              <Typography>Simplified Debt</Typography>
            </MenuItemContent>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setActiveTab(3);
              handleMobileMenuClose();
            }}
            selected={activeTab === 3}
          >
            <MenuItemContent>
              <HistoryIcon />
              <Typography>Activity</Typography>
            </MenuItemContent>
          </MenuItem>
        </MobileMenu>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" }, mb: 3 }}>
        <StyledTabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={
              <TabLabel>
                <ReceiptIcon sx={{ fontSize: 20 }} />
                <span>Bills ({totalBills})</span>
              </TabLabel>
            }
          />
          <Tab
            label={
              <TabLabel>
                <GroupIcon sx={{ fontSize: 20 }} />
                <span>Members ({group.members.length})</span>
              </TabLabel>
            }
          />
          <Tab
            label={
              <TabLabel>
                <AccountBalanceIcon sx={{ fontSize: 20 }} />
                <span>Simplified Debt</span>
              </TabLabel>
            }
          />
          <Tab
            label={
              <TabLabel>
                <HistoryIcon sx={{ fontSize: 20 }} />
                <span>Activity</span>
              </TabLabel>
            }
          />
          ;
        </StyledTabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        {renderBillsTab()}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ContentContainer>
          {group.members.length === 0
            ? renderEmptyState("members")
            : group.members.map((member) => (
                <Box
                  key={member.userId}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 2,
                    p: 2,
                    backgroundColor: (theme) =>
                      alpha(theme.palette.background.default, 0.4),
                    borderRadius: 1,
                    "&:last-child": { mb: 0 },
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      backgroundColor: (theme) =>
                        alpha(theme.palette.background.default, 0.6),
                    },
                  }}
                >
                  <Avatar
                    src={member.photoURL || "/api/placeholder/40/40"}
                    alt={member.displayName}
                    sx={{ width: 48, height: 48 }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" fontWeight={500}>
                      {member.displayName || member.email}
                      {member.userId === group.createdBy && (
                        <Chip
                          label="Creator"
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ ml: 1, height: 20 }}
                        />
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {member.email}
                    </Typography>
                  </Box>
                </Box>
              ))}
        </ContentContainer>
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <GroupSimplifiedDebts
          groupId={groupId}
          onUpdate={() => setBillsRefresh((prev) => prev + 1)}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <GroupActivityTimeline
          activities={activities}
          loading={loadingActivities}
          error={activitiesError}
        />
      </TabPanel>

      <EditGroupModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        group={group}
        onUpdate={handleGroupUpdate}
      />
      {/* Invite Modal */}
      <InviteModal
        open={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        type="group"
        groupId={groupId}
        groupName={group?.name}
        onInviteSuccess={handleInviteSuccess}
      />
      {/* Success Snackbar */}
      <Snackbar
        open={inviteSuccess}
        autoHideDuration={3000}
        onClose={() => setInviteSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setInviteSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Invite sent successfully!
        </Alert>
      </Snackbar>
      {/* Leave Group Dialog */}
      <StyledDialog
        open={isLeaveDialogOpen}
        onClose={() => setIsLeaveDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
      >
        <Box sx={{ p: 2.5 }}>
          {/* Title */}
          <StyledDialogTitle>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Leave Group</Typography>
              <IconButton
                onClick={() => setIsLeaveDialogOpen(false)}
                size="small"
                sx={{ color: "text.secondary" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </StyledDialogTitle>
          {/* Warning Message */}
          <StyledDialogContent>
            {cannotLeaveReason && (
              <Box
                sx={{
                  p: 2,
                  mt: 2,
                  mb: 2,
                  borderRadius: 1,
                  bgcolor: "rgba(255, 178, 55, 0.1)", // Warning background color
                  display: "flex",
                  gap: 1.5,
                  alignItems: "flex-start",
                }}
              >
                <WarningIcon color="warning" />
                <Typography color="warning.main">
                  {cannotLeaveReason}
                </Typography>
              </Box>
            )}
          </StyledDialogContent>

          {/* Actions */}
          <StyledDialogActions>
            <ActionButton
              onClick={() => setIsLeaveDialogOpen(false)}
              disabled={leavingGroup}
              variant="text"
              color="inherit"
            >
              Cancel
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={handleLeaveGroup}
              disabled={!!cannotLeaveReason || leavingGroup}
              startIcon={
                leavingGroup ? <CircularProgress size={20} /> : <SendIcon />
              }
            >
              {leavingGroup ? "Leaving..." : "Leave Group"}
            </ActionButton>
          </StyledDialogActions>
        </Box>
      </StyledDialog>
    </PageContainer>
  );
};

export default GroupDetails;
